import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postItems } from '../services/api';
import '../styles/OrderConfirmation.css';

const OrderConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = React.useState(null);
  const hasProcessedRef = useRef(false);

  useEffect(() => {
    const confirmOrder = async () => {
      // Check if we've already processed this order
      if (hasProcessedRef.current) {
        return;
      }

      if (!location.state || !location.state.orderDetails) {
        setError('No order details found');
        return;
      }

      // Mark as processed immediately
      hasProcessedRef.current = true;

      const { reservationID, quantity, itemID } = location.state.orderDetails;

      try {
        const result = await postItems(reservationID, quantity, itemID);
        if (result.success) {
          navigate(`/order-confirmed/${reservationID}`);
        } else {
          throw new Error(result.message || 'Failed to post item');
        }
      } catch (error) {
        console.error('Error posting item:', error);
        setError(error.message || 'An error occurred while processing your order. Please contact support.');
        // Reset the flag if there's an error, allowing for retry
        hasProcessedRef.current = false;
      }
    };

    confirmOrder();
  }, [location.state, navigate]);

  if (error) {
    return (
      <div className="order-confirmation-container">
        <p className="order-confirmation-error">{error}</p>
        <button onClick={() => navigate('/dashboard')} className="order-confirmation-button">
          {t('returnToDashboard')}
        </button>
      </div>
    );
  }

  return (
    <div className="order-confirmation-container">
      <p className="order-confirmation-message">Payment Received, Confirming your Order...</p>
    </div>
  );
};

export default OrderConfirmation;

