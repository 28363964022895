import React, { useState, useEffect } from "react";
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useParams } from "react-router-dom";
import UnlockingPreloader from "../components/UnlockingPreloader";
import { getHotelReservationById, unlockDoor } from "../services/api";
import BottomNavigation from "../components/BottomNavigation";
import lockIcon from "../assets/unlock.png";
import "../styles/UnlockRoom.css";
import { useTranslation } from 'react-i18next';

const UnlockRoom = () => {
  const { t } = useTranslation();
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [reservationData, setReservationData] = useState(null);
  const [buttonText, setButtonText] = useState(t('clickToUnlock'));
  const [isUnlockingPreloader, setIsUnlockingPreloader] = useState(false);
  const [isDoorKeypad, setIsDoorKeypad] = useState(true);
  const navigate = useNavigate();
  const { reservationId, roomIdentifier } = useParams();

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await getHotelReservationById(reservationId);
        if (response.success) {
          setReservationData(response.data[0]);
          setIsDoorKeypad(response.data[0].hotelDetails.isDoorKeypad);
        }
      } catch (error) {
        console.error("Error fetching reservation data:", error);
      }
    };
    fetchReservationData();
  }, [reservationId]);

  const handleUnlock = async () => {
    if (!isUnlocked && reservationData) {
      setIsUnlockingPreloader(true);
      try {
        const token = reservationData.guestDetails.ttLockAccessToken;
        const propertyId = reservationData.hotelDetails.propertyId;
        const response = await unlockDoor(token, roomIdentifier, propertyId);

        if (response.success) {
          if (
            response.message === "Currently Locked" &&
            response.data.state === 0
          ) {
            setButtonText(t('roomIsCurrentlyLocked'));
          } else {
            setButtonText(t('roomHasBeenUnlockedSuccessfully'));
            setIsUnlocked(true);
          }
          navigate(`/unlock-success/${roomIdentifier}`);
        } else {
          if (!isDoorKeypad) {
            navigate(
              `/unlock-failed-help-support/${reservationId}/${roomIdentifier}`
            );
          } else {
            navigate(`/unlock-failed/${reservationId}/${roomIdentifier}`);
          }
        }
      } catch (error) {
        console.error("Error unlocking door:", error);
        setButtonText(t('failedToUnlockRedirecting'));
        if (!isDoorKeypad) {
          navigate(
            `/unlock-failed-help-support/${reservationId}/${roomIdentifier}`
          );
        } else {
          navigate(`/unlock-failed/${reservationId}/${roomIdentifier}`);
        }
      } finally {
        setIsUnlockingPreloader(false);
      }
    }
  };

  if (isUnlockingPreloader) {
    return <UnlockingPreloader />;
  }

  return (
    <div className="unlock-room">
      <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>{t('unlockYourRoom')}</h1>
      </div>
      <div className="room-number">
        <span>Room: {roomIdentifier}</span>
      </div>
      <div className="unlock-room-container">
        <div className="lock-icon">
          <img src={lockIcon} alt="Lock" />
        </div>

        <button
          className={`unlock-button ${isUnlocked ? "unlocked" : ""}`}
          onClick={handleUnlock}
          disabled={isUnlocked}
        >
          {buttonText}
        </button>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockRoom;

