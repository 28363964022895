import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/ShareKeySuccess.css";
import Loading from "../components/Loading";
import { useTranslation } from 'react-i18next';

const ShareKeySuccess = () => {
  const navigate = useNavigate();
  const { reservationId } = useParams();
  const { t } = useTranslation();

  const handleBackToUnlockingDetails = () => {
    navigate(`/unlocking-details/${reservationId}`);
  };

  return (
    <div className="share-key-success__container">
      <div className="share-key-success__header">
        <header className="share-key-success__title">
          <h1>{t('shareRoomEKey')}</h1>
        </header>
      </div>

      <div className="share-key-success__card">
        <button className="share-key-success__close-button" onClick={handleBackToUnlockingDetails}>×</button>
        <div className="share-key-success__content">
          <div className="share-key-success__check-icon">✓</div>
          <h2 className="share-key-success__heading">
            {t('roomEKeyShared')}
          </h2>
          <p className="share-key-success__details">
            {t('eKeySharedDetails')}
          </p>
          <p className="share-key-success__note">
            {t('sharedKeyNote')}
          </p>
          <button
            className="share-key-success__details-button"
            onClick={handleBackToUnlockingDetails}
          >
            {t('backToUnlockingDetails')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareKeySuccess;

