import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPasscode } from "../services/api";
import "../styles/UnlockRoom.css";
import lockIcon from "../assets/unlockingFailed.png";
import BottomNavigation from "../components/BottomNavigation";
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const UnlockFailed = () => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState(t('fetchingPasscode'));
  const navigate = useNavigate();
  const { reservationId, roomIdentifier } = useParams();

  useEffect(() => {
    const fetchPasscode = async () => {
      try {
        const passcodeResponse = await getPasscode(
          reservationId,
          roomIdentifier
        );
        if (passcodeResponse && passcodeResponse.passcode) {
          setButtonText(
            t('unlockingFailed', { passcode: passcodeResponse.passcode })
          );
        } else {
          setButtonText(t('failedToRetrievePasscode'));
        }
      } catch (passcodeError) {
        console.error("Error fetching passcode:", passcodeError);
        setButtonText(t('failedToRetrievePasscode'));
      }
    };
    fetchPasscode();
  }, [reservationId, roomIdentifier, t]);

  return (
    <div className="unlock-room">
      <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>{t('unlockFailed')}</h1>
      </div>
      <div className="room-number">
        <span>Room: {roomIdentifier}</span>
      </div>
      <div className="unlock-room-container">
        <div className="lock-icon">
          <img src={lockIcon} alt="Lock" />
        </div>
        <button className="unlock-button unlocked" disabled>
          {buttonText}
        </button>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockFailed;

