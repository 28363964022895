import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ArrowLeft } from 'lucide-react';
import Loading from "../components/Loading";
import { getHotelReservationById, keyShare } from "../services/api";
import "../styles/ShareKey.css";
import { useTranslation } from 'react-i18next';

const ShareKey = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    selectedRoom: "",
    phoneNumber: "",
    countryCode: "+44",
    email: "",
    agreeToShare: false,
  });
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await getHotelReservationById(reservationId);
        if (response.success) {
          setRooms(response.data[0].bookingDetails.rooms);
          setPropertyId(response.data[0].hotelDetails.propertyId);
          const bookingDetails = response.data[0].bookingDetails;
          const correctReservationId = bookingDetails.reservationId;
          setFormData(prevData => ({
            ...prevData,
            reservationId: correctReservationId
          }));
        } else {
          throw new Error("Failed to fetch reservation data");
        }
      } catch (error) {
        console.error("Error fetching reservation data:", error);
        setError("Failed to load room data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchReservationData();
  }, [reservationId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.agreeToShare) {
      alert(t('agreeToShareAlert'));
      return;
    }

    setIsSubmitting(true);

    try {
      await keyShare(
        `${formData.firstName} ${formData.lastName}`.trim(),
        formData.email,
        `${formData.countryCode}${formData.phoneNumber}`.replace(/[^0-9+]/g, ''),
        formData.selectedRoom,
        formData.reservationId,
        propertyId
      );
    } catch (error) {
      console.error("Error sharing key:", error);
    } finally {
      navigate(`/share-key-success/${reservationId}`);
    }
  };

  if (isLoading) return <Loading />;
  if (error) return <div className="share-key__error">{error}</div>;

  return (
    <div className="share-key__container">
      <div className="share-key__header">
        <Link to={`/unlocking-details/${reservationId}`}>
          <button className="share-key__back-button">
            <ArrowLeft size={24} />
          </button>
        </Link>
        <h1 className="share-key__title">{t('shareRoomKey')}</h1>
      </div>

      <form onSubmit={handleSubmit} className="share-key__form">
        <div className="share-key__form-row">
          <div className="share-key__form-group">
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder={t('firstName')}
              required
              className="share-key__form-input"
            />
          </div>
          <div className="share-key__form-group">
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder={t('lastName')}
              required
              className="share-key__form-input"
            />
          </div>
        </div>

        <div className="share-key__form-group">
          <select
            id="selectedRoom"
            name="selectedRoom"
            value={formData.selectedRoom}
            onChange={handleInputChange}
            required
            className="share-key__form-input"
          >
            <option value="">{t('selectRoom')}</option>
            {rooms.map((room, index) => (
              <option key={index} value={room.roomName}>
                {room.roomName || `Room ${index + 1}`}
              </option>
            ))}
          </select>
        </div>

        <div className="share-key__form-group">
          <div className="share-key__phone-input">
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleInputChange}
              className="share-key__country-code"
            >
              <option value="+44">UK (+44)</option>
              <option value="+1">US (+1)</option>
              <option value="+91">IN (+91)</option>
              <option value="+33">FR (+33)</option>
              <option value="+49">DE (+49)</option>
              <option value="+86">CN (+86)</option>
              <option value="+81">JP (+81)</option>
              <option value="+61">AU (+61)</option>
            </select>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder={t('phoneNumber')}
              required
              pattern="[0-9]*"
              className="share-key__form-input share-key__phone-number"
            />
          </div>
        </div>

        <div className="share-key__form-group">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={t('email')}
            required
            className="share-key__form-input"
          />
        </div>

        <div className="share-key__form-group share-key__checkbox-group">
          <input
            type="checkbox"
            id="agreeToShare"
            name="agreeToShare"
            checked={formData.agreeToShare}
            onChange={handleInputChange}
            required
            className="share-key__checkbox"
          />
          <label htmlFor="agreeToShare" className="share-key__checkbox-label">
            {t('agreeToShare')}
          </label>
        </div>

        <div className="share-key__warning-message">
          {t('shareWarning')}
        </div>

        <button 
          type="submit" 
          className="share-key__submit-button" 
          disabled={!formData.agreeToShare || isSubmitting}
        >
          {isSubmitting ? t('sharing') : t('shareRoomEkey')}
        </button>
      </form>
    </div>
  );
};

export default ShareKey;

