import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import GuestLogin from "./screens/GuestLogin";
import GuestDashboard from "./screens/GuestDashboard";
import PreCheckInForm from "./screens/PreCheckInForm";
import PreCheckInSuccess from "./screens/PreCheckInSuccess";
import BookingDetails from "./screens/BookingDetails";
import UnlockingDetails from "./screens/UnlockingDetails";
import UnlockRoom from "./screens/UnlockRoom";
import UnlockSuccess from "./screens/UnlockSuccess";
import UnlockFailed from "./screens/UnlockFailed";
import UnlockFailedHelpSupport from "./screens/UnlockFailedHelpSupport";
import HelpSupport from "./screens/Help&Support";
import ShareKey from "./screens/ShareKey";
import ShareKeySuccess from "./screens/ShareKeySuccess";
import Upsell from "./screens/Upsell";
import OrderConfirmation from "./screens/OrderConfirmation";
import OrderConfirmed from "./screens/OrderConfirmed";
import "./index.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const isSmallScreen = window.innerWidth <= 768;
      setIsMobile(isSmallScreen);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="mobile-only-message">
        This application is only available on mobile devices.
      </div>
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/" element={<GuestLogin />} />
          <Route path="/dashboard" element={<GuestDashboard />} />
          <Route
            path="/pre-check-in/:reservationId"
            element={<PreCheckInForm />}
          />
          <Route path="/pre-check-in-success" element={<PreCheckInSuccess />} />
          <Route
            path="/bookingdetails/:reservationId"
            element={<BookingDetails />}
          />
          <Route
            path="/unlocking-details/:reservationId"
            element={<UnlockingDetails />}
          />
          <Route
            path="/unlock-room/:reservationId/:roomIdentifier"
            element={<UnlockRoom />}
          />
          <Route
            path="/unlock-success/:roomIdentifier"
            element={<UnlockSuccess />}
          />
          <Route
            path="/unlock-failed/:reservationId/:roomIdentifier"
            element={<UnlockFailed />}
          />
          <Route
            path="/unlock-failed-help-support/:reservationId/:roomIdentifier"
            element={<UnlockFailedHelpSupport />}
          />
          <Route path="/help-support" element={<HelpSupport />} />
          <Route path="/share-key/:reservationId" element={<ShareKey />} />
          <Route 
            path="/share-key-success/:reservationId/" 
            element={<ShareKeySuccess />} 
          />
          <Route path="/upsell/:propertyID/:category/:reservationId" element={<Upsell />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/order-confirmed/:reservationId" element={<OrderConfirmed />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;

