import axios from "axios";

const API = axios.create({
  baseURL: "https://wieldyportal.co.uk",
  //baseURL: "http://localhost:8080",
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': 'true',
    'Connection': 'keep-alive',
    'Keep-Alive': 'timeout=5'
  }
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (credentials) => {
  try {
    const response = await API.post("/guest/login", credentials);
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const getHotelReservations = async () => {
  try {
    const response = await API.get("/guest/user-Hotel-reservations");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch hotel reservations:", error);
    throw error;
  }
};

export const getHotelReservationById = async (reservationId) => {
  try {
    const response = await API.get(
      `/guest/user-Hotel-reservations/${reservationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching hotel reservation by ID:", error);
    throw error;
  }
};

export const getUnlockingDetails = async (reservationId) => {
  try {
    const response = await API.get(
      `/guest/user-Hotel-reservations/${reservationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unlocking details:", error);
    throw error;
  }
};

export const unlockDoor = async (token, room, propertyId) => {
  try {
    const response = await API.post("/unlockDoor", { token, room, propertyId });
    return response.data;
  } catch (error) {
    console.error("Error unlocking door:", error);
    throw error;
  }
};

export const getPasscode = async (reservationId, room) => {
  try {
    const response = await API.get("/guest/getPasscode", {
      params: { reservationId, room },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching passcode:", error);
    throw error;
  }
};

export const getItems = async (propertyID, category) => {
  try {
    const response = await API.get(`/cloudBeds/api/getItems`, {
      params: { propertyID, category },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const postItems = async (reservationID, itemQuantity, itemID) => {
  try {
    // Ensure all parameters are strings to match Postman request
    const params = new URLSearchParams({
      reservationID: String(reservationID),
      itemQuantity: String(itemQuantity),
      itemID: String(itemID)
    });
    
    // Log the exact URL being constructed
    const url = `/cloudBeds/api/postItem?${params.toString()}`;
    console.log('Posting to URL:', url);
    
    const response = await API.post(url);
    return response.data;
  } catch (error) {
    console.error('Error Posting items:', error);
    throw error;
  }
};

export const keyShare = async (sharedGuestName, sharedGuestEmail, sharedGuestContact, sharedRoom, reservationID, propertyId) => {
  try {
    const response = await API.post("/guest/keyShare", {
      sharedGuestName,
      sharedGuestEmail,
      sharedGuestContact,
      sharedRoom,
      reservationID,
      propertyId
    });
    return response.data;
  } catch (error) {
    console.error("Error in Key Sharing:", error);
    throw error;
  }
};


export const checkIn = async (payload) => {
  try {
    const response = await API.post("/cloudBeds/api/checkin", payload);
    return response.data;
  } catch (error) {
    console.error("Check-in error:", error);
    throw error;
  }
};

export const uploadPreCheckInData = async (formData) => {
  try {
    const response = await API.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to upload pre-check-in data:", error);
    throw error;
  }
};

export const createPaymentIntent = async (items) => {
  try {
    const response = await API.post("/guest/stripe/create-payment-intent", { items });
    return response.data;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    throw error;
  }
};

export default API;

