import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { ArrowLeft, Plus, Minus } from "lucide-react"
import { useTranslation } from "react-i18next"
import { getItems, getHotelReservations, createPaymentIntent } from "../services/api"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../components/CheckoutForm"
import Loading from "../components/Loading"
import "../styles/Upsell.css"

const stripePromise = loadStripe(
  "pk_test_51PzbmJP0cqunWEjfRJnhqhtd5qVqkyPZtVBfMtuXleSvEzMsnfaNnNGte1PMW1EN8UtX9J7gU2IqPXhb5q7nFlKB00QGN3F7B0",
)

const Upsell = () => {
  const { t } = useTranslation()
  const { propertyID, category, reservationId } = useParams()
  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [quantities, setQuantities] = useState({})
  const [numberOfRooms, setNumberOfRooms] = useState(1)
  const [reservationData, setReservationData] = useState(null)
  const [clientSecret, setClientSecret] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)
  const [showCheckout, setShowCheckout] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const [itemsResponse, reservationsResponse] = await Promise.all([
          getItems(propertyID, category),
          getHotelReservations(),
        ])

        if (reservationsResponse.data && reservationsResponse.data.length > 0) {
          setReservationData(reservationsResponse)
        }

        if (itemsResponse.sucess) {
          setItems(itemsResponse.data)

          const rooms = reservationsResponse.data[0]?.bookingDetails?.rooms?.length || 1
          setNumberOfRooms(rooms)

          const initialQuantities = itemsResponse.data.reduce((acc, item) => {
            if (category === "WieldyEarlyCheckIn" || category === "WieldyLateCheckout") {
              acc[item.itemID] = rooms
            } else {
              acc[item.itemID] = 0
            }
            return acc
          }, {})
          setQuantities(initialQuantities)
        } else {
          throw new Error(itemsResponse.message || "Failed to fetch items")
        }
      } catch (err) {
        console.error("Error fetching data:", err)
        setError(err instanceof Error ? err.message : "An error occurred")
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [propertyID, category])

  const handleQuantityChange = (itemID, change) => {
    if (category === "WieldyEarlyCheckIn" || category === "WieldyLateCheckout") {
      return
    }
    setQuantities((prev) => ({
      ...prev,
      [itemID]: Math.max(0, prev[itemID] + change),
    }))
  }

  const handleBuyNow = async (item) => {
    try {
      setLoading(true)

      const bookingReservationId = reservationData?.data?.[0]?.bookingDetails?.reservationId

      if (!bookingReservationId) {
        throw new Error("Reservation ID not found in booking details")
      }

      const amount = Math.round(item.price * quantities[item.itemID] * 100) // Convert to cents

      // Format the items array according to the API requirements
      const requestData = {
        items: [
          {
            amount: amount,
          },
        ],
      }

      console.log("Creating payment intent with:", requestData)

      const data = await createPaymentIntent(requestData.items)

      if (!data.clientSecret) {
        throw new Error("No client secret received from the server")
      }

      setClientSecret(data.clientSecret)
      setPaymentAmount(amount)
      setSelectedItem({
        ...item,
        quantity: quantities[item.itemID],
        reservationID: bookingReservationId,
      })
      setShowCheckout(true)
    } catch (error) {
      console.error("Error initiating payment:", error)
      alert(error.message || "An error occurred while initiating payment. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  const isQuantityFixed = category === "WieldyEarlyCheckIn" || category === "WieldyLateCheckout"

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="upsell-error">
        <p className="upsell-error-message">{error}</p>
        <button onClick={() => navigate("/dashboard")} className="upsell-error-button">
          {t("returnToDashboard")}
        </button>
      </div>
    )
  }

  const displayCategoryName =
    items[0]?.categoryName?.replace("Wieldy", "") || category?.replace("Wieldy", "") || "Early Check-in"

  return (
    <div className="upsell-container">
      <div className="upsell-header">
        <div className="upsell-header-content">
          <button onClick={() => navigate(-1)} className="upsell-back-button">
            <ArrowLeft size={24} />
          </button>
          <h1 className="upsell-title">{displayCategoryName}</h1>
        </div>
      </div>

      <div className="upsell-content">
        {items.length === 0 ? (
          <div className="upsell-empty">
            <p>{t("noItemsAvailable")}</p>
          </div>
        ) : (
          <div className="upsell-grid">
            {items.map((item) => (
              <div key={item.itemID} className="upsell-card">
                <div className="upsell-card-content">
                  <div className="upsell-card-header">
                    <h3 className="upsell-card-title">{item.name}</h3>
                    <div className="upsell-card-price">£{Number(item.price).toFixed(2)}</div>
                  </div>
                  <p className="upsell-card-description">{item.description}</p>
                  <div className="upsell-quantity-wrapper">
                    <div className={`upsell-quantity-selector ${isQuantityFixed ? "fixed" : ""}`}>
                      <button
                        onClick={() => handleQuantityChange(item.itemID, -1)}
                        className="upsell-quantity-button"
                        disabled={isQuantityFixed || quantities[item.itemID] === 0}
                      >
                        <Minus size={16} />
                      </button>
                      <span className="upsell-quantity">{quantities[item.itemID]}</span>
                      <button
                        onClick={() => handleQuantityChange(item.itemID, 1)}
                        className="upsell-quantity-button"
                        disabled={isQuantityFixed}
                      >
                        <Plus size={16} />
                      </button>
                    </div>
                    {isQuantityFixed && <span className="upsell-fixed-quantity-note">{t("fixedQuantityNote")}</span>}
                  </div>
                  <button
                    onClick={() => handleBuyNow(item)}
                    className="upsell-buy-button"
                    disabled={quantities[item.itemID] === 0}
                  >
                    {t("buyNow")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {showCheckout && clientSecret && (
        <Elements options={{ clientSecret }} stripe={stripePromise}>
          <CheckoutForm onClose={() => setShowCheckout(false)} amount={paymentAmount} selectedItem={selectedItem} />
        </Elements>
      )}
    </div>
  )
}

export default Upsell

