import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from 'lucide-react';
import "../styles/UnlockRoom.css";
import lockIcon from "../assets/unlockingFailed.png";
import BottomNavigation from "../components/BottomNavigation";
import { getHotelReservations } from "../services/api";
import { useTranslation } from 'react-i18next';

const UnlockFailedHelpSupport = () => {
  const navigate = useNavigate();
  const { reservationId, roomIdentifier } = useParams();
  const [reservationData, setReservationData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await getHotelReservations();
        if (response.success && response.data.length > 0) {
          setReservationData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching reservation data:", error);
      }
    };

    fetchReservationData();
  }, []);

  const handleBackToUnlocking = () => {
    navigate(`/unlocking-details/${reservationId}`);
  };

  const handleNavigateToSupport = () => {
    navigate("/help-support", { state: { reservationData } });
  };

  return (
    <div className="unlock-room">
      <div className="header">
        <button className="back-button" onClick={handleBackToUnlocking}>
          <ArrowLeft />
        </button>
        <h1>{t('unlockFailed')}</h1>
      </div>
      <div className="room-number">
        <span>Room: {roomIdentifier || t('notAvailable')}</span>
      </div>
      <div className="unlock-room-container">
        <div className="lock-icon">
          <img src={lockIcon} alt="Lock" />
        </div>
        <button
          className="unlock-button unlocked"
          onClick={handleNavigateToSupport}
        >
          {t('unlockingFailedConnectToSupportTeam')}
        </button>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockFailedHelpSupport;

