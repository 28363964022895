import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  getHotelReservationById,
  unlockDoor,
  checkIn,
} from "../services/api";
import UnlockingPreloader from "../components/UnlockingPreloader";
import BottomNavigation from "../components/BottomNavigation";
import "../styles/UnlockingDetails.css";
import hotelImg from "../assets/hotel-checkIn-img.jpg";

const UnlockingDetails = () => {
  const { t } = useTranslation();
  const [reservationData, setReservationData] = useState(null);
  const [redirectToHelpSupport, setRedirectToHelpSupport] = useState(false);
  const [buttonText, setButtonText] = useState(t("tapToUnlockFrontDoor"));
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [checkInMessage, setCheckInMessage] = useState("");
  const [isUnlockingPreloader, setIsUnlockingPreloader] = useState(false);

  const { reservationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await getHotelReservationById(reservationId);
        if (response.success) {
          setReservationData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching reservation data:", error);
      }
    };
    fetchReservationData();
  }, [reservationId]);

  useEffect(() => {
    if (reservationData) {
      console.log("Reservation data updated:", reservationData);
      console.log("Rooms available:", reservationData.bookingDetails?.rooms?.length);
      console.log("Booking status:", reservationData.bookingDetails?.status);
    }
  }, [reservationData]);

  const handleUnlock = async () => {
    if (redirectToHelpSupport) {
      navigate("/help-support");
      return;
    }

    if (!isUnlocked) {
      setIsUnlockingPreloader(true);
      try {
        const token = reservationData.guestDetails.ttLockAccessToken;
        const room = "2024";
        const propertyId = reservationData.hotelDetails.propertyId;

        const unlockPromise = unlockDoor(token, room, propertyId);
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Unlock operation timed out")),
            20000
          )
        );

        const response = await Promise.race([unlockPromise, timeoutPromise]);

        if (response.success) {
          setButtonText(t('frontDoorUnlockedSuccessfully'));
          setIsUnlocked(true);
        } else {
          throw new Error("Unlocking failed");
        }
      } catch (error) {
        console.error("Unlock error:", error);
        setButtonText(t("Unlock Failed, Please Try Again"));
        setRedirectToHelpSupport(true);
      } finally {
        setIsUnlockingPreloader(false);
      }
    }
  };

  const handleRoomClick = async (room) => {
    if (!reservationData) return;

    const { bookingDetails, hotelDetails } = reservationData;
    const roomIdentifier = room.roomName || room.roomId;

    // Always navigate to UnlockRoom for In-House or shared status
    if (bookingDetails.status === "In-House" || bookingDetails.status === "shared") {
      navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);
      return;
    }

    setIsUnlockingPreloader(true);

    try {
      const token = localStorage.getItem("token");
      const payload = {
        bookingid: bookingDetails._id,
        reservationid: bookingDetails.reservationId,
        propertyID: hotelDetails.propertyId,
      };

      const response = await checkIn(payload, token);

      console.log("Check-in response:", response); // For debugging

      // Navigate to UnlockRoom regardless of the response
      navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);

    } catch (error) {
      console.error("Check-in error:", error);
      // Navigate to UnlockRoom even if there's an error
      navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);
    } finally {
      setIsUnlockingPreloader(false);
    }
  };

  const handleShareKey = () => {
    console.log("Share key button clicked");
    console.log("Rooms available:", reservationData?.bookingDetails?.rooms?.length);
    console.log("Booking status:", reservationData?.bookingDetails?.status);
    
    if (reservationData?.bookingDetails?.rooms?.length > 0 && reservationData.bookingDetails.status !== "shared") {
      console.log("Navigating to share key page");
      navigate(`/share-key/${reservationId}/`);
    }
  };

  if (!reservationData || isUnlockingPreloader) {
    return <UnlockingPreloader />;
  }

  const { hotelDetails, bookingDetails } = reservationData;

  return (
    <div className="unlocking-details-wrapper">
      <div className="container">
        <p className="UnlockingDetailHeading">{t('unlockingDetails')}</p>

        <div className="unlocking-hotel-card">
          <img
            src={hotelDetails.propertyImage || hotelImg}
            alt={hotelDetails.propertyName}
            className="hotel-image"
          />
          <div className="hotel-overlay">
            {bookingDetails.rooms.length > 0 && bookingDetails.status !== "shared" && (
              <div className="button-container">
                <button
                  className="share-key-button"
                  onClick={handleShareKey}
                >
                  {t('shareRoomEKeyWithOthers')}
                </button>
              </div>
            )}
          </div>
          <div className="hotel-name">{hotelDetails.propertyName}</div>
        </div>

        <div className="frontdoor-btn">
          {hotelDetails.isFrontDoor && (
            <div className="unlockbtn">
              <h3>{t('frontDoorKey')}</h3>
              <button
                className={`unlock-button-frontdoor ${
                  isUnlocked ? "disabled" : ""
                }`}
                onClick={handleUnlock}
                disabled={isUnlocked}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>

        {checkInMessage && <p className="check-in-message">{checkInMessage}</p>}

        <div className="room-list">
          <h3>{t('roomList')}</h3>
          {bookingDetails.rooms.length === 0 ? (
            <p className="text-muted-foreground text-center py-4">{t('There is no available Rooms.')}</p>
          ) : (
            bookingDetails.rooms.map((room, index) => (
              <button
                key={index}
                className="room-item"
                onClick={() => handleRoomClick(room)}
              >
                <span className="room-item-text">
                  {t('roomNumberLabel')} {room.roomName || room.roomId || `Room ${index + 1}`}
                </span>
                <span className="arrow">
                  <ChevronRight />
                </span>
              </button>
            ))
          )}
        </div>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockingDetails;

