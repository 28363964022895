import React, { useState, useRef } from "react";
import { X } from 'lucide-react';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import '../styles/CheckoutForm.css';

export default function CheckoutForm({ onClose, amount, selectedItem }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const hasSubmittedRef = useRef(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || hasSubmittedRef.current) {
      return;
    }

    setIsLoading(true);
    setMessage(null);
    hasSubmittedRef.current = true;

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        setMessage(error.message);
        hasSubmittedRef.current = false; // Reset flag on error
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        navigate('/order-confirmation', {
          state: {
            orderDetails: {
              paymentIntentId: paymentIntent.id,
              amount: amount,
              ...selectedItem
            }
          },
          replace: true // Use replace to prevent back navigation to payment form
        });
      } else {
        setMessage("Unexpected payment status. Please try again.");
        hasSubmittedRef.current = false; // Reset flag on unexpected status
      }
    } catch (error) {
      console.error('Payment error:', error);
      setMessage("An unexpected error occurred. Please try again.");
      hasSubmittedRef.current = false; // Reset flag on error
    } finally {
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <div className="checkout-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="checkout-modal">
        <div className="checkout-header">
          <h2 className="checkout-title">Complete Payment</h2>
          <button className="checkout-close" onClick={onClose}>
            <X size={24} />
          </button>
        </div>
        
        <div className="checkout-amount">
          Total: £{(amount / 100).toFixed(2)}
        </div>

        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
}

