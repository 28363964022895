import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, CheckCircle } from 'lucide-react';
import '../styles/OrderConfirmed.css';

const OrderConfirmed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reservationId } = useParams();

  return (
    <div className="order-confirmed-container">
      <div className="order-confirmed-header">
        <button 
          onClick={() => navigate('/dashboard')}
          className="order-confirmed-back-button"
        >
          <ArrowLeft size={24} />
        </button>
        <h1>{t('orderConfirmed')}</h1>
      </div>
      <div className="order-confirmed-content">
        <div className="order-confirmed-icon">
          <CheckCircle size={64} color="#4CAF50" />
        </div>
        <h2>{t('orderConfirmedMessage')}</h2>
        <p className="order-confirmed-reservation-id">{t('reservationId')}: {reservationId}</p>
        <button 
          onClick={() => navigate('/dashboard')} 
          className="order-confirmed-dashboard-button"
        >
          {t('returnToDashboard')}
        </button>
      </div>
    </div>
  );
};

export default OrderConfirmed;

